<template>
  <v-card class="mx-auto mt-5" elevation="4" max-width="800">
    <v-card-title class="justify-center">Create Campaign</v-card-title>
    <v-card-text>
      You can now order seminars through AnnuityCheck.com directly!<br />
      Please login to <a href="https://annuitycheck.com">AnnutiyCheck</a> to
      proceed.
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card__title {
  text-align: center;
  width: 100%;
}
</style>

<script>
export default {
  name: "CreateSeminar",

  components: {},
  async mounted() {
    // clear maybe?
    // get accurate pricing
    // TODO: HANDLE PRICING
    // this.get_by_pk("users", this.$store.state.user.id, "all_in").then(
    //   (result) => {
    //     if (result.all_in) {
    //       this.$store.state.create_seminar.pricing = 0.44;
    //     }
    //   }
    // );
  },
  methods: {
    submit: function() {
      if (this.$refs.form.validate()) {
        alert("Valid");
      } else {
        alert("Not Valid");
      }
    },
  },
  data: () => {
    return {
      steps: 9,
      stepNames: [
        "Group Registration",
        "Select a Template",
        "Select A Venue",
        "Edit Content",
        "Review Mailer",
        "Select Demographics",
        "Appointment and Follow Up",
        "Final Approval",
        "Checkout",
      ],
    };
  },
};
</script>
